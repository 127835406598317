<div class="services-container">
  <h2 style="border-bottom: 2px solid #ac8053; padding-bottom: 10px">
    Nos Projets Réalisés
  </h2>
  <div class="container">
    <div class="row">
      <div class="col-md-6">
        <div class="product-item">
          <img
            src="./../../assets/newproject1.jpeg"
            alt="Importation Internationale"
          />
          <p style="font-size: 16px">
            EXTENSION KEC DU PROJET HIRSCHMANN AUTOMOTIVE A L’AFZ
          </p>
          <br>
          <div style="font-weight: 700; font-size: 16px; color: #8f8f8f">
            Étude des hangars en charpente métallique, des planchers collaborant
            et bâtiment en béton armé avec dalles alvéolaires.
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="product-item">
          <img
            src="./../../assets/newproject2.jpeg"
            alt="Importation Internationale"
          />
          <p style="font-size: 16px">
            PROJET D’EXTENSION DU CLUB DES ADHERENTS DE LA FONDATION MOHAMMED VI
            A RABAT
          </p>
          <div style="font-weight: 700; font-size: 16px; color: #8f8f8f">
            Étude d’exécution de la charpente en bois du club Aquagym<br><br>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <div class="product-item">
          <img
            src="./../../assets/newproject3.png"
            alt="Importation Internationale"
          />
          <p style="font-size: 16px">
            PROJET D’AMÉNAGEMENT DE LA PLACE PERDICARIS À TANGER<br><br>
          </p>
          <div style="font-weight: 700; font-size: 16px; color: #8f8f8f">
            Étude d’exécution de la structure en charpente métallique et des
            façades vitrées<br><br>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="product-item">
          <img
            src="./../../assets/newproject4.png"
            alt="Importation Internationale"
          />
          <p style="font-size: 16px">
            CONSTRUCTION DU CENTRE D’INFORMATION TOURISTIQUE DE LA VILLE
            D’AGADIR
          </p>
          <div
            style="
              font-weight: 700;
              font-size: 16px;
              color: #8f8f8f;
              
            "
          >
            Étude d’exécution de la couverture courbée en charpente bois lamellé
            collé<br><br>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <div class="product-item">
          <img
            src="./../../assets/newproject5.png"
            alt="Importation Internationale"
          />
          <p style="font-size: 16px">
            PROJET DE CONSTRUCTION DU CHU IBN SINA À RABAT<br><br>
          </p>
          <div style="font-weight: 700; font-size: 16px; color: #8f8f8f">
            Étude d’exécution de la structure métallique du bâtiment CCV<br><br>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="product-item">
          <img
            src="./../../assets/newproject6.jpeg"
            alt="Importation Internationale"
          />
          <p style="font-size: 16px">
            CONSTRUCTION DU MUSÉE DE LA FONDATION DR LEILA MEZIAN<br><br>
          </p>
          <div
            style="
              font-weight: 700;
              font-size: 16px;
              color: #8f8f8f;
              
            "
          >
            Étude de la structure métallique support de la couverture en bois
            lamellé collé avec des panneaux en CLT
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <div class="product-item">
          <img
            src="./../../assets/newproject7.jpeg"
            alt="Importation Internationale"
          />
          <p style="font-size: 16px">
            RÉAMÉNAGEMENT DE KITEA AGADIR POUR UNE SALLE DE SPORT CITY CLUB
          </p>
          <div style="font-weight: 700; font-size: 16px; color: #8f8f8f">
            Étude d’une structure métallique avec des planchers
            collaborants<br><br>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="product-item">
          <img
            src="./../../assets/newproject8.png"
            alt="Importation Internationale"
          />
          <p style="font-size: 16px">
            CONSTRUCTION D’UN ESPACE DE RECRIATION ET PISCINE «AGHROUD
            BENSERGAO»
          </p>
          <div
            style="
              font-weight: 700;
              font-size: 16px;
              color: #8f8f8f;
            "
          >
            Étude d’exécution de la couverture en bois lamellé collé<br><br>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <div class="product-item">
          <img
            src="./../../assets/project13.jpeg"
            alt="Importation Internationale"
          />
          <p style="font-size: 16px">
            CONSTRUCTION DE LA RÉSIDENCE HÔTELIÈRE DOMOTEL A RABAT<br><br>
          </p>
          <div style="font-weight: 700; font-size: 16px; color: #8f8f8f">
            Étude des façades avec des panneaux en béton fibré à ultra hautes
            performances (BFUP) à motif Moucharabieh
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="product-item">
          <img
            src="./../../assets/newproject10.jpeg"
            alt="Importation Internationale"
          />
          <p style="font-size: 16px">
            CONSTRUCTION D'UNE PISCINE OLYMPIQUE À AGADIR<br><br>
          </p>
          <div
            style="
              font-weight: 700;
              font-size: 16px;
              color: #8f8f8f;
              
            "
          >
            Étude de la couverture courbée en charpente bois<br><br>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <div class="product-item">
          <img
            src="./../../assets/newproject11.jpeg"
            alt="Importation Internationale"
          />
          <p style="font-size: 16px">
            PROJET DE REAMENAGEMENT ET MISE EN VALEUR DE LA GARE RABAT VILLE
          </p>
          <div style="font-weight: 700; font-size: 16px; color: #8f8f8f">
            Étude de la verrière en charpente métallique<br><br>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="product-item">
          <img
            src="./../../assets/newproject12.png"
            alt="Importation Internationale"
          />
          <p style="font-size: 16px">PROJET LE CARROUSEL HOTEL A RABAT<br><br></p>
          <div
            style="
              font-weight: 700;
              font-size: 16px;
              color: #8f8f8f;
              
            "
          >
            Étude des brises soleil et éléments décoratifs en façade<br><br>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
