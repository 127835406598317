<!-- services.component.html -->
<div class="services-container">
  <h2 style="border-bottom: 2px solid #ac8053; padding-bottom: 10px">
    Nos Missions
  </h2>
  <p>
    Notre domaine d'intervention s'étend à toutes les étapes d'un projet de
    construction, de la conception jusqu'à la mise en service. Nous offrons un
    accompagnement complet permettant de concevoir des projets optimisés et
    durables.
  </p>
  <div class="row">
    <div class="col-md-4 col-sm-12">
      <div class="product-item">
        <img src="./../../assets/mission1.jpeg" alt="Hero Image" />
        Etudes des ouvrages en charpente métallique
        <br><br>
      </div>
    </div>
    <div class="col-md-4 col-sm-12">
      <div class="product-item">
        <img src="./../../assets/mission2.jpeg" alt="Hero Image" />
        Etudes des ouvrages en béton armé et en béton préfabriqué
        <br><br>
      </div>
    </div>
    <div class="col-md-4 col-sm-12">
      <div class="product-item">
        <img src="./../../assets/mission33.jpeg" alt="Hero Image" />
        Etudes des façades vitrées et verrières<br><br>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-4 col-sm-12">
      <div class="product-item">
        <img src="./../../assets/mission4.jpeg" alt="Hero Image" />
        Etudes des façades en béton architectonique<br><br>
      </div>
    </div>
    <div class="col-md-4 col-sm-12">
      <div class="product-item">
        <img src="./../../assets/mission5.jpeg" alt="Hero Image" />
        Etudes des couvertures en bois lamellé collé<br><br>
      </div>
    </div>
    <div class="col-md-4 col-sm-12">
      <div class="product-item">
        <img src="./../../assets/mission55.jpeg" alt="Hero Image" />
        Etudes des structures métallo-textiles<br><br>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-4 col-sm-12">
      <div class="product-item">
        <img src="./../../assets/mission6.jpeg" alt="Hero Image" />
        Gestion des projets en BIM, notamment modélisation 3D, synthèse
        architecturale et technique sur des maquettes numériques
      </div>
    </div>
    <div class="col-md-4 col-sm-12">
      <div class="product-item">
        <img src="./../../assets/mission7.jpeg" alt="Hero Image" />
        Assistance technique aux maîtres d’ouvrage et élaboration des dossiers
        des appels d’offres
      </div>
    </div>
    <div class="col-md-4 col-sm-12">
      <div class="product-item">
        <img src="./../../assets/mission8.jpeg" alt="Hero Image" />
        Suivi des études et travaux en phase EXE<br><br>
      </div>
    </div>
  </div>
</div>
