<nav class="navbar navbar-expand-lg fixed-header">
  <div class="container-fluid">
    <a class="navbar-brand" href="#">
      <img
        src="./../../assets/tlh-logo.png"
        class="logo-img"
      />
    </a>
    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <ul class="navbar-nav mx-auto">
        <li class="nav-item" [routerLinkActive]="['active']">
          <a class="nav-link" routerLink="/home">ACCUEIL</a>
        </li>
        <li class="nav-item" [routerLinkActive]="['active']">
          <a class="nav-link" routerLink="/about">À PROPOS</a>
        </li>
        <li class="nav-item" [routerLinkActive]="['active']">
          <a class="nav-link" routerLink="/products">MISSIONS</a>
        </li>
        <li class="nav-item" [routerLinkActive]="['active']">
          <a class="nav-link" routerLink="/references">REFERENCES</a>
        </li>
        <li class="nav-item" [routerLinkActive]="['active']">
          <a class="nav-link" routerLink="/contact">CONTACT</a>
        </li>
      </ul>
    </div>
    <div class="d-flex align-items-center icons">
      <a href="mailto:tlh.ingenierie@gmail.com" class="header-icon" style="cursor: pointer;">
        <i class="fas fa-envelope"></i>
      </a>
      <a
        href="https://www.linkedin.com/in/hanae-touda-lachiri-0aaa9078/"
        target="_blank"
        class="header-icon"
      >
        <i class="fa-brands fa-linkedin-in"></i>
      </a>
      <button class="navbar-toggler" type="button" (click)="toggleSideNav()">
        <span class="navbar-toggler-icon"></span>
      </button>
    </div>
  </div>
</nav>

<div class="sidenav" [ngClass]="{ open: isSideNavOpen }">
  <a routerLink="/home" [routerLinkActive]="['active']" (click)="closeSideNav()"
    >Accueil</a
  >
  <a
    routerLink="/about"
    [routerLinkActive]="['active']"
    (click)="closeSideNav()"
    >À Propos</a
  >
  <a
    routerLink="/products"
    [routerLinkActive]="['active']"
    (click)="closeSideNav()"
    >Missions</a
  >
  <a
    routerLink="/references"
    [routerLinkActive]="['active']"
    (click)="closeSideNav()"
    >Références</a
  >
  <a
    routerLink="/contact"
    [routerLinkActive]="['active']"
    (click)="closeSideNav()"
    >Contact</a
  >
</div>
