<div class="home">
  <div class="hero-container">
    <carousel [interval]="2000">
      <slide>
        <div class="hero-container">
          <img src="./../../assets/project13.jpeg" alt="Hero Image" />
        </div>
      </slide>
      <slide>
        <div class="hero-container">
          <img src="./../../assets/newproject1.jpeg" alt="Hero Image" />
        </div>
      </slide>
      <slide>
        <div class="hero-container">
          <img src="./../../assets/newproject3.png" alt="Hero Image" />
        </div>
      </slide>
      <slide>
        <div class="hero-container">
          <img src="./../../assets/newproject11.jpeg" alt="Hero Image" />
        </div>
      </slide>
      <slide>
        <div class="hero-container">
          <img src="./../../assets/newproject5.png" alt="Hero Image" />
        </div>
      </slide>
      <slide>
        <div class="hero-container">
          <img src="./../../assets/newproject6.jpeg" alt="Hero Image" />
        </div>
      </slide>
      <slide>
        <div class="hero-container">
          <img src="./../../assets/newproject2.jpeg" alt="Hero Image" />
        </div>
      </slide>
      <slide>
        <div class="hero-container">
          <img src="./../../assets/newproject7.jpeg" alt="Hero Image" />
        </div>
      </slide>
      <slide>
        <div class="hero-container">
          <img src="./../../assets/newproject8.png" alt="Hero Image" />
        </div>
      </slide>
      <slide>
        <div class="hero-container">
          <img src="./../../assets/newproject10.jpeg" alt="Hero Image" />
        </div>
      </slide>
      <slide>
        <div class="hero-container">
          <img src="./../../assets/newproject12.png" alt="Hero Image" />
        </div>
      </slide>
    </carousel>
  </div>

  <div class="about-us-container">
    <div class="about-us-content">
      <h2 style="text-align: center">À propos</h2>
      <div class="row">
        <div class="col-md-12">
          <p class="about-text">
            <span style="font-weight: bold">TLH INGÉNIERIE</span> est un bureau
            d'études spécialisé dans l'ingénierie du bâtiment et son enveloppe.
            Qu'il s'agit d'un bâtiment avec des murs rideaux, des habillages
            muraux, des verrières ou avec une coque métallique de forme
            complexe, TLH INGÉNIERIE saura vous apporter les meilleures
            solutions en termes d'optimisation d'esthétique et de performance.
          </p>
        </div>
      </div>
      <button class="btn-about" routerLink="/about">En savoir plus</button>
    </div>
  </div>

  <div class="products-section">
    <div class="text-center">
      <h2>Missions</h2>
      <div class="row">
        <div class="col-md-3 col-sm-12">
          <div class="product-item">
            <span style="border-bottom: 2px solid #ac8053; padding-bottom: 10px"
              >Etudes des ouvrages en charpente métallique</span
            >
            <img src="./../../assets/mission1.jpeg" alt="Hero Image" />
          </div>
        </div>
        <div class="col-md-3 col-sm-12">
          <div class="product-item">
            <span style="border-bottom: 2px solid #ac8053; padding-bottom: 10px"
              >Etudes des ouvrages en béton armé</span
            >
            <img src="./../../assets/mission2.jpeg" alt="Hero Image" />
          </div>
        </div>
        <div class="col-md-3 col-sm-12">
          <div class="product-item">
            <span style="border-bottom: 2px solid #ac8053; padding-bottom: 10px"
              >Etudes des façades vitrées et verrières</span
            >
            <img src="./../../assets/mission33.jpeg" alt="Hero Image" />
          </div>
        </div>
        <div class="col-md-3 col-sm-12">
          <div class="product-item">
            <span style="border-bottom: 2px solid #ac8053; padding-bottom: 10px"
              >Etudes des façades en béton architectonique</span
            >
            <img src="./../../assets/mission4.jpeg" alt="Hero Image" />
          </div>
        </div>
      </div>
      <button class="btn-products" routerLink="/products">Découvrir</button>
    </div>
  </div>

  <div class="services-container">
    <h2>Références</h2>
    <div class="row">
      <div class="col-md-4 col-sm-12">
        <div class="mission-item">
          <img src="./../../assets/newproject1.jpeg" alt="Hero Image" />
          <span>EXTENSION KEC DU PROJET HIRSCHMANN AUTOMOTIVE A L’AFZ</span>
        </div>
      </div>
     <div class="col-md-4 col-sm-12">
        <div class="mission-item">
          <img src="./../../assets/newproject5.png" alt="Hero Image" />
          <span style="margin-left: 10px;">PROJET DE CONSTRUCTION DU CHU IBN SINA À RABAT</span>
        </div>
      </div>
      <div class="col-md-4 col-sm-12">
        <div class="mission-item">
          <img src="./../../assets/newproject3.png" alt="Hero Image" />
          <span style="margin-left: 10px;">PROJET D’AMÉNAGEMENT DE LA PLACE PERDICARIS À TANGER</span>
        </div>
      </div>
    </div>
    <div style="text-align: center; margin: 10px">
      <button
        class="btn-about"
        style="text-align: center"
        routerLink="/references"
      >
        En savoir plus
      </button>
    </div>
  </div>

  <!-- <div class="import-section">
    <h2 class="mt-5 mb-4" style="color: #00384a">
      Ils nous ont fait confiance
    </h2>
    <div class="container">
      <div class="row trust">
        <div class="col-md-1 col-4">
          <img src="./../../assets/jet.jpeg" alt="Logo de JET CONTRACTORS" />
        </div>
        <div class="col-md-1 col-4">
          <img src="./../../assets/bpmi.png" alt="Logo de BPMI" />
        </div>
        <div class="col-md-1 col-4">
          <img src="./../../assets/wood.png" alt="Logo de Wood Company" />
        </div>
        <div class="col-md-1 col-4">
          <img src="./../../assets/ots.png" alt="Logo de OTS" />
        </div>
        <div class="col-md-1 col-4">
          <img src="./../../assets/ama.jpeg" alt="Logo de AMA" />
        </div>
        <div class="col-md-1 col-4">
          <img src="./../../assets/shape.png" alt="Logo de Shape" />
        </div>
        <div class="col-md-2 col-4">
          <img src="./../../assets/omnium.png" alt="Logo de Omnium" />
        </div>
      </div>
    </div>
  </div> -->

  <div class="container">
    <h2
      class="location-title"
      style="border-bottom: 2px solid #ac8053; padding-bottom: 10px"
    >
      Contactez-Nous
    </h2>
    <div class="row">
      <div class="col-md-6" style="text-align: left">
        <p style="margin-bottom: 10px; font-weight: 600">
          <i
            class="fa fa-envelope"
            aria-hidden="true"
            style="color: #ac8053; margin-right: 5px"
          ></i>
          tlh.ingenierie@gmail.com
        </p>
        <p style="margin-bottom: 10px; font-weight: 600">
          <i
            class="fas fa-map-marker-alt"
            style="color: #ac8053; margin-right: 5px"
          ></i>
          15, Avenue Al Abtal Appt. N°4 Agdal Rabat
        </p>
        <p style="font-weight: 600">
          <i
            class="fa-solid fa-phone"
            style="color: #ac8053; margin-right: 5px"
          ></i>
          +212 668 76 33 99
        </p>
      </div>
    </div>
  </div>
</div>
