<footer class="footer-section">
  <div class="container">
    <div class="footer-widgets">
      <div class="row">
        <div class="col-md-5">
          <div class="foo-block">
            <img
              src="./../../assets/tlh-logo.png"
              style="width: 150px"
            />
            <p style="font-size: 16px; margin-top: 20px">
              <strong>TLH INGÉNIERIE</strong><br />
            </p>
          </div>
          <div class="row">
            <div style="text-align: left">
              <p style="margin-bottom: 10px; font-weight: 600">
                <i class="fa fa-envelope" aria-hidden="true"></i>
                tlh.ingenierie@gmail.com
              </p>
              <p style="margin-bottom: 10px; font-weight: 600">
                <i class="fas fa-map-marker-alt"></i> 15, Avenue Al Abtal
                Appt. N°4 Agdal Rabat
              </p>
              <p style="font-weight: 600">
                <i class="fa-solid fa-phone"></i> +212 668 76 33 99
              </p>
            </div>
          </div>
        </div>
        <div class="col-md-3">
          <div class="foo-block">
            <h4>Liens Rapides</h4>
            <p routerLink="/home" class="foot-link">Accueil</p>
            <p routerLink="/about" class="foot-link">À Propos</p>
            <p routerLink="/products" class="foot-link">Missions</p>
            <p routerLink="/references" class="foot-link">References</p>
            <p routerLink="/contact" class="foot-link">Contact</p>
          </div>
        </div>

        <div class="col-md-4">
          <div class="foo-block text-center" style="padding: 50px">
            <a
              href="https://www.linkedin.com/in/hanae-touda-lachiri-0aaa9078/"
              target="_blank"
              class="header-icon"
            >
              <i class="fa-brands fa-linkedin-in"></i>
            </a>
          </div>
        </div>
      </div>
    </div>
    <div class="copyright">
      <div class="footer-copy">
        <span class="footer-year">2024</span>
        © TLH INGÉNIERIE
      </div>
      <ul class="footer-social"></ul>
    </div>
  </div>
</footer>
