<div class="location-contact-section">
  <div class="container">
    <h2 class="location-title" style="border-bottom: 2px solid #ac8053;padding-bottom: 10px;">Contactez-Nous</h2>
    <div class="row">
      <div class="col-md-6" style="text-align: left">
        <p style="margin-bottom: 10px; font-weight: 600">
          <i class="fa fa-envelope" aria-hidden="true" style="color: #ac8053;margin-right: 5px;"></i>
          tlh.ingenierie@gmail.com
        </p>
        <p style="margin-bottom: 10px; font-weight: 600">
          <i class="fas fa-map-marker-alt" style="color: #ac8053;margin-right: 5px;"></i> 15, Avenue Al Abtal Appt. N°4
          Agdal Rabat
        </p>
        <p style="font-weight: 600">
          <i class="fa-solid fa-phone" style="color: #ac8053;margin-right: 5px;"></i> +212 668 76 33 99
        </p>
      </div>
    </div>
  </div>
</div>
