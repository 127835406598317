<!-- about.component.html -->
<div class="about-us-container">
  <div class="about-us-content">
    <h2 style="border-bottom: 2px solid #ac8053;padding-bottom: 10px;">À propos de TLH INGÉNIERIE</h2>
    <div class="row">
      <div class="col-md-8 col-sm-12 text-left">
        <p>
          <span class="bold-text">TLH INGÉNIERIE</span> est un bureau
          d'études spécialisé dans l'ingénierie du bâtiment et son enveloppe.
          Qu'il s'agisse d'un bâtiment avec des murs rideaux, des habillages
          muraux, des verrières ou avec une coque métallique de forme complexe,
          TLH INGÉNIERIE saura vous apporter les meilleures solutions en termes
          d'optimisation esthétique et de performance.
        </p>
      </div>
    </div>
    <div>
      <div class="row">
        <div class="col-md-6 col-sm-12">
          <h3 class="mt-4 mb-4" style="color: #00384a;" style="border-bottom: 2px solid #ac8053;padding-bottom: 10px;margin-right:40%;">Fondatrice du BET</h3>
          <h5 class="mb-1 bold-text">
            Mme. Hanae TOUDA LACHIRI
          </h5>
          <div class="engineer-title">
            Ingénieur Génie Civil de l'EHTP
          </div>
          <p>
            <span class="bold-text">TLH INGÉNIERIE</span> a été fondé par Mme. Hanae Touda Lachiri, ingénieur
            structure et façade, ayant 8 ans d'expérience dans les études
            d'exécution des bâtiments et leurs enveloppes au sein du Groupe JET
            CONTRACTORS. Nous mettons notre savoir-faire et nos compétences au
            profit d'autres acteurs de la construction en capitalisant sur notre
            expérience et en apportant les solutions les plus optimisées.
          </p>
        </div>
        <div class="col-md-6 col-sm-12 mt-5 text-center">
          <img
            src="./../../assets/hanae-picture.jpeg"
            alt="Photo de Hanae Touda Lachiri"
            class="hanae-img"
          />
        </div>
      </div>
    </div>
  </div>
</div>
